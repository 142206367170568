
import { Stack } from '../Ui'
import { Popover } from '../Ui/Popover/Popover'
export type NoteAnnotationPopoverProps = {
    open: boolean
    anchorElRef: any
    dummyData: string
    onClose: any
    // onClick: () => void
}

export const TitlePopup = ({
    open,
    anchorElRef,
    dummyData,
    onClose,
}: NoteAnnotationPopoverProps) => {

    return (
        <Popover contentProps={{ sx: { minWidth: 300,maxWidth:800 } }} anchorEl={anchorElRef.current} open={open} onClose={onClose}>
            <Stack padding={2}>
                {/* <Typography color={TlaccBlue.A700} onClick={onClick}>{dummyCitation}</Typography> */}
                <div style={{whiteSpace:'pre-line'}} dangerouslySetInnerHTML={{__html:dummyData}} />
            </Stack>
        </Popover>
    )
}

export default TitlePopup
