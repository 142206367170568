import { useMemo, useState, useEffect, useRef} from "react";
import { TextField } from "@fluentui/react";
import styles from "./ChatHistory.module.css";
import eventBus from "../../plugins/EventBus"
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { cloneDeep, filter, isEmpty, map } from 'lodash'
import {isMobile} from 'react-device-detect';
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { MoreVertical24Regular } from "@fluentui/react-icons";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { editChatSession, pinChatSession, deleteChatSession, RuleChat, deleteFeedbackById, DraftStatus, ItemsDraftStatus } from "../../api";
import { ClearAllHistory } from "./ClearAllHistory";
import { NavbarChatHeader } from "./NavbarChatHeader";
import { useStore } from "../../hooks/useStore";
import { eventDateConvertedV2 } from "../../utils/dateConvert";
import DropdownMenuDemo from "../Ui/DropdownMenu/DropdownMenu";

import addChat from "../../assets/thunghiemred.svg"
import Delete from "../../assets/delete.svg"
import CloseSidebar from "../../assets/Group.svg"
import logo_mimi from "../../assets/ic_mimi_white.png"
import ic_logout from "../../assets/uiv2/ic_logout_v2.svg"


import { themeChange } from "../../utils/theme";
import { toggleSession } from "../../utils/toggleSession";
import { EmptyChatHistory } from "./EmptyHistoryChat";
import { ModalConfirm } from "../Ui/Modal";
import showToasty from "../../hooks/useNotify";
import { TabDraft } from "../Draft/ui/draft/tabDraft";
import { TabItemDraft } from "../Draft/ui/items";
import { TabHistory } from "../Draft/ui/draft";
import { colorStatus } from "../Answer";
import { useColorMode } from "../context/ColorModeContext";
import { ButtonProps } from "../Ui/Button";
import Render from "@components/Ui/Render/Render";
import { ListItem, Skeleton } from "@mui/material";
import { Stack } from "@components/Ui";


interface PropsChatDetails {
    status: string
    chat: any;
    chatBySession: (chat: any) => void
    onHandleClickAction: (chat: any, action: string) => void
    disabledRate?: boolean
}

export const ChatHistoryListItemCell = observer(({
    status,
    chat,
    chatBySession,
    onHandleClickAction,
    disabledRate
  }: PropsChatDetails) => {
    const {
        rootStore: { sessionChatsStore, draftStore },
    } = useStore();
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const action = status === 'draft' ? 'draft' : status === 'pin' ? 'unpin' : 'pin'

    const ItemAction = (): any => {
        if(sessionChatsStore.ruleChat === RuleChat.draft && draftStore.activeDraft === DraftStatus.sent) return
        return (
            <div className={`${styles.conversationActionMore} ${disabledRate && styles.disabled}`}>
                <Dropdown drop="end" >
                    <Dropdown.Toggle variant="success" className={`${styles.dropdownDot} isMoreAction`}>
                        <MoreVertical24Regular className="isMoreActionDot"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ zIndex: '99999999' }} popperConfig={{ strategy: "fixed", }} renderOnMount>
                        {
                            action !== 'draft' ? <>
                                <Dropdown.Item onClick={() => onHandleClickAction(chat, action)}>
                                    <div className="dropdownitem" style={{ marginLeft: '2px', display: 'flex', alignItems: 'center', color: 'var(--color-text-dropdown)' }}>
                                        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="dropdownitemSvg">
                                            <path d="M7.66699 2.33329V6.49996C7.66699 7.43329 7.97533 8.29996 8.50033 8.99996H3.50033C4.04199 8.28329 4.33366 7.41663 4.33366 6.49996V2.33329H7.66699ZM10.167 0.666626H1.83366C1.37533 0.666626 1.00033 1.04163 1.00033 1.49996C1.00033 1.95829 1.37533 2.33329 1.83366 2.33329H2.66699V6.49996C2.66699 7.88329 1.55033 8.99996 0.166992 8.99996V10.6666H5.14199V16.5L5.97533 17.3333L6.80866 16.5V10.6666H11.8337V8.99996C10.4503 8.99996 9.33366 7.88329 9.33366 6.49996V2.33329H10.167C10.6253 2.33329 11.0003 1.95829 11.0003 1.49996C11.0003 1.04163 10.6253 0.666626 10.167 0.666626Z" fill="var(--color-icon-dropdown)" />
                                        </svg>
                                        <span className="dropdownitem-text" style={{ marginLeft: '13px' }}>{status === 'pin' ? 'Bỏ ghim' : 'Ghim'}</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => onHandleClickAction(chat, 'rename')}>
                                    <div className="222" style={{ display: 'flex', alignItems: 'center', color: 'var(--color-text-dropdown)' }}>
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="dropdownitemSvg">
                                            <path d="M15.9819 9.02023C15.5981 9.02023 15.287 9.30741 15.287 9.66166V16.0758C15.287 16.4301 14.9759 16.7172 14.5922 16.7172H2.0846C1.70082 16.7172 1.38972 16.4301 1.38972 16.0758V3.24749C1.38972 2.89323 1.70082 2.60606 2.0846 2.60606H10.423C10.8068 2.60606 11.1179 2.31889 11.1179 1.96463C11.1179 1.61038 10.8068 1.32324 10.423 1.32324H2.0846C0.933307 1.32324 0 2.18476 0 3.24749V16.0758C0 17.1385 0.933307 18 2.0846 18H14.5922C15.7435 18 16.6768 17.1385 16.6768 16.0758V9.66163C16.6768 9.30741 16.3657 9.02023 15.9819 9.02023Z" fill="var(--color-icon-dropdown)" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.9919 1.04671e-05C16.6637 -6.70634e-05 17.308 0.266793 17.7831 0.7419C18.772 1.73099 18.7719 3.33456 17.7828 4.32354L9.0683 13.038C8.99569 13.1108 8.90711 13.1656 8.80962 13.1981L4.84006 14.5213C4.77261 14.5435 4.70202 14.5549 4.631 14.555C4.56014 14.555 4.4897 14.5435 4.42244 14.5211C4.07576 14.4057 3.88833 14.0311 4.00382 13.6844L5.32699 9.71481C5.35959 9.61751 5.41413 9.52897 5.48643 9.45613L14.201 0.742326C14.6747 0.265397 15.3197 -0.00192779 15.9919 1.04671e-05ZM8.24576 11.9894L16.8465 3.38864C17.0738 3.1621 17.2012 2.85414 17.2004 2.53321C17.1994 2.21397 17.0724 1.90808 16.8469 1.68212C16.3748 1.2091 15.6086 1.20833 15.1356 1.68041L6.53156 10.2811L5.67613 12.8474L8.24576 11.9894Z" fill="var(--color-icon-dropdown)" />
                                        </svg>

                                        <span className="dropdownitem-text" style={{ marginLeft: '8px' }}>Đổi tên</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                            </> : null
                        }
                        <Dropdown.Item onClick={() => onHandleClickAction(chat, 'delete')}>
                            <div className="222" style={{ display: 'flex', alignItems: 'center', color: 'var(--color-text-dropdown)' }}>
                                <img src={Delete} alt="" style={{ color: '#3DBFFE', marginRight: '8px' }} className="dropdownitemSvg"/>
                                <span className="dropdownitem-text">Xóa</span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }
    const Svg_Chat = () => {
        const color = chat.active ? '#EE0033' : 'var(--color-ic-chat)'
        return (
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.83366 2.33329H18.167V13.8333H3.95491L2.83366 14.9545V2.33329ZM2.83366 0.416626C1.77949 0.416626 0.926576 1.27913 0.926576 2.33329L0.916992 19.5833L4.75033 15.75H18.167C19.2212 15.75 20.0837 14.8875 20.0837 13.8333V2.33329C20.0837 1.27913 19.2212 0.416626 18.167 0.416626H2.83366ZM4.75033 9.99996H12.417V11.9166H4.75033V9.99996ZM4.75033 7.12496H16.2503V9.04163H4.75033V7.12496ZM4.75033 4.24996H16.2503V6.16663H4.75033V4.24996Z" 
                fill={color}/>
            </svg>
        )
    }

      return (
        <div className="">
            <div className={`${chat.active ? 'borderLeftActive' : ''}`}></div>
            <div 
                className={`${styles.conversation} ${chat.active ? styles.conversationActive : ''}`} 
                style={{ height: draftStore.activeDraft === DraftStatus.sent && action === 'draft' ? '100px' : '64px', position: 'relative' }} 
                onClick={() => chatBySession(chat)}
                onMouseEnter={!isMobile ? () => setIsHovered(true) : undefined}
                onMouseLeave={() => setIsHovered(false)}    
            >
                { action !== 'draft' &&
                    <div className={`${styles.iconContainer}`}>
                        <Svg_Chat/>
                    </div>
                } 
                {
                    action !== 'draft' ?
                        <div className="" style={{ position: 'relative' }}>
                            <div className="" style={{ display: 'flex', justifyContent: 'space-between', width: '210px' }}>
                                <span className={`${styles.conversationTittle} ${styles.conversationMessages} ${chat.active ? styles.colorTextActive : styles.colorTextNoActive}`} style={{ fontSize: '15px', fontWeight: '600', wordWrap: 'break-word',  }}>
                                    {chat.sessionName}</span>
                                <div style={{ color: 'var(--color-date-message)', fontSize: '13px', fontWeight: '400', wordWrap: 'break-word' }}>
                                    {eventDateConvertedV2(chat?.createdAt)?.date}</div>

                            </div>
                            <span className={`${styles.conversationTittle} ${styles.conversationMessages}`} style={{ left: 0, top: '26px', position: 'absolute', width: `205px`, color: 'var(--color-text-last-message)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>
                                {chat?.firstMessage}
                            </span>

                        </div> :
                        <div className="" style={{ position: 'relative' }}>
                            <div className="" style={{ display: 'flex', justifyContent: 'space-between', width: '246px' }}>
                                <span className={`${styles.conversationTittle} ${styles.conversationMessages} ${chat.active ? styles.colorTextActive : styles.colorTextNoActive}`} style={{ fontSize: '15px', fontWeight: '600', wordWrap: 'break-word',  }}>
                                    {chat.sessionName}</span>
                                <div style={{ color: 'var(--color-date-message)', fontSize: '13px', fontWeight: '400', wordWrap: 'break-word' }}>
                                    {eventDateConvertedV2(chat?.createdAt)?.date}</div>
        
                            </div>
                            <span className={`${styles.conversationTittle} ${styles.conversationMessages}`} style={{ left: 0, top: '26px', position: 'absolute', width: '241px', color: 'var(--color-text-last-message)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word' }}>
                                {chat?.firstMessage}
                            </span>
                            <span className={`${styles.conversationTittle} ${styles.conversationMessages}`} style={{ left: 0, top: '56px', position: 'absolute', color: 'white', fontSize: 12, fontWeight: '600', wordWrap: 'break-word', padding: '2px 6px 2px 6px', borderRadius: 4, backgroundColor: colorStatus(chat?.status) }}>
                                {chat?.status}
                            </span>
                        </div>
                }
                
                {   isHovered && !isMobile &&
                    <ItemAction/>
                }
                {   chat?.active && isMobile &&
                    <ItemAction/>
                }
                {   sessionChatsStore.ruleChat === RuleChat.draft && draftStore.activeDraft === DraftStatus.unsent && isMobile &&
                    <ItemAction/>
                }
            </div>
        </div>
      );
  });


  export const ChatHistory = observer(({
    setIsOpen,
    height,
    setIdChatBySessionName,
    chatBySession,
    chatSessionList,
    setChatSessionList,
    onNewContribute,
    isMobile,
    loadingChats
}: any) => {
    const navigate = useNavigate()
    const params = useParams();
    
    const {
        rootStore: { sessionChatsStore, draftStore },
    } = useStore();

    const drafts = sessionChatsStore.drafts
    const [show, setShow] = useState(false);
    const [styleNewChat, setStyleNewChat] = useState({})
    const [textAction, setTextAction] = useState<string>("")
    const [textInput, setTextInput] = useState<string>("")
    const [sessionId, setSessionId] = useState<string>("")
    const [displayOpenSidebar, setDisplayOpenSidebar] = useState<boolean>(!isMobile ? false : true)
    const storedTheme = localStorage.getItem("theme");
    const [themeLocal, setThemeLocal] = useState<string | null>(storedTheme)
    const { toggleColorMode } = useColorMode()

    const modalTitle = () => {
        switch (textAction) {
            case "pin":
              return "Ghim cuộc trò chuyện này";
            case "rename":
              return "Đổi tên cho cuộc trò chuyện này";
            case "delete":
              return sessionChatsStore.ruleChat === RuleChat.chat
                ? "Bạn muốn xoá cuộc trò chuyện?"
                : "Xóa bản nháp?";
            default:
              return "";
          }
    }
    const modalFooter = () => {
        return (
            <>
                <Button variant={'light'} onClick={handleClose}>
                    Hủy
                </Button>
                <Button onClick={handleAction}>
                    {textAction === 'pin' ? 'Ghim' : textAction === 'rename' ? 'Đổi tên' : textAction === 'delete' ? 'Xác nhận' : ''}
                </Button>
            </>
        )
    }


    const disabledRate = !isEmpty(sessionChatsStore.getMessageRating) && sessionChatsStore.ruleChat === RuleChat.chat
    const onClickNewChat = () => {
        if (disabledRate) {
            showToasty("Vui lòng hoàn thành đánh giá!", "error");
            return;
        }
        navigate(`/chat`)
        setIdChatBySessionName("")
        const conversationTmp = cloneDeep(chatSessionList)
                conversationTmp.map((item: any, key: any) => {
                    item.active = false
                    return item;
                });
        setChatSessionList(conversationTmp);
        setIsOpen()
        eventBus.dispatch('newChatSession', {})
    };
    const onClickCloseChatSesionMenu = () => {
        setIsOpen(false)
    }

    const onActionInputChange = (_ev?: React.FormEvent, newValue?: string) => {
        setTextInput(newValue || "");
    };
    const handleClose = () => setShow(false);
    const onHandleClickAction = (chat: any, action: string) => {
        setTextAction(action)
        if (action === 'unpin') {
            onUnPinChatSession(chat?.id)
            setShow(false)
        } else if (action === 'pin') {
            onPinChatSession(chat?.id)
            setShow(false)
        } else {
            setShow(true)
        }
        setTextInput(chat?.sessionName || "");
        setSessionId(chat?.id || "");
    }

    const onEditChatSession = async () => {
        const request: any = {
            sessionId: sessionId,
            sessionName: textInput
        }
        const result = await editChatSession(request)
        sessionChatsStore.editItem(sessionId, textInput)
        handleClose()
    }
    const onPinChatSession = async (id: string) => {
        const request: any = {
            sessionId: id,
            pinned: true
        }
        const result = await pinChatSession(request)
        eventBus.dispatch('reloadChatPinUnpin', {id: id, pinned: true})
        handleClose()
    }
    const onUnPinChatSession = async (id: string) => {
        const request: any = {
            sessionId: id,
            pinned: false
        }
        const result = await pinChatSession(request)
        eventBus.dispatch('reloadChatPinUnpin', {id: id, pinned: false})
        handleClose()
    }
    const onDeleteChatSession = async () => {
        try {
            if (sessionChatsStore.ruleChat === RuleChat.chat) {
                // Xóa hội thoại
                const request: any = {
                    sessionId: sessionId,
                }
                const result = await deleteChatSession(request)
                setIdChatBySessionName("")
                sessionChatsStore.removeChatById(sessionId)
                showToasty("Xóa hội thoại thành công", 'success');
                if(params.id && params.id === sessionId) {
                    navigate(`/chat`)
                    eventBus.dispatch('clearChatByDelete', {})
                }
            } else if (sessionChatsStore.ruleChat === RuleChat.draft) {
              // Xóa bản nháp
              const request: any = {
                    feedbackId: sessionId,
                }
                const result = await deleteFeedbackById(request)
                sessionChatsStore.SET_DRAFT(sessionId)
                showToasty("Xóa bản nháp đóng góp thành công", 'success');

            } else {
              console.error('Unexpected ruleChat value:', sessionChatsStore.ruleChat);
            }
          } catch (error) {
            console.error('Error deleting', error);
          } finally {
            handleClose();
          }
    }
    const handleAction = () => {
        textAction === 'rename' ? onEditChatSession() :
        textAction === 'delete' ? onDeleteChatSession() : null
    }

    const logOut = () => {
        eventBus.dispatch('logOutNavbar', {});
    }
    const openChatSession = () => {
        const bar = document.querySelector(".activeNav")
        if(!bar) return
        toggleSession()
    }

    const onClickRuleSearch = (rule: string) => {
        sessionChatsStore.SET_RULE_SEARCH(rule)
        openChatSession()
        onClickNewChat()
    }
    const onClickRuleChat = (rule: string) => {
        if(sessionChatsStore.ruleChat === rule) {
            onClickNewChat()
        }
        sessionChatsStore.SET_RULE_CHAT(rule)
        openChatSession()
    }

    const closeSidebar = () => {
        const bar = document.querySelector(".activeNav")
        if(bar) return
        toggleSession()
    }
    const openSidebar = () => {
        eventBus.dispatch('closeAnalysisPanel', {})
        const temp = document.querySelector("#historyContainer")
        temp?.classList.remove("historyContainerClose");
        setDisplayOpenSidebar(false)
    }

    const redirectHomePage = () => {
        navigate("/");
        eventBus.dispatch('redirectHomePageNavbar', {});
    }

    const clickNewContribute = () => {
        onNewContribute()
    }

    const listChatUnpin = useMemo(() => {
        const result = chatSessionList.filter((v: any) => v.pinned)
        return result
    }, [chatSessionList])
    const listChatPin = useMemo(() => {
        const result = chatSessionList.filter((v: any) => !v.pinned)
        return result
    }, [chatSessionList])

    const countDraft = useMemo(() => {
        const items: any[] = draftStore.activeDraft === DraftStatus.sent ? filter(drafts, v => !v.isDraft) : filter(drafts, v => v.isDraft)
        return items?.length > 9 ? items?.length : `0${items?.length}`
    }, [drafts])

    useEffect(() => {
        if(!params?.id) {
            setStyleNewChat((prev) => ({...prev, opacity: '0.6', cursor: 'initial'}))
        }else {
            setStyleNewChat((prev) => ({...prev, opacity: '1', cursor: 'pointer'}))
        }
    }, [params])

    const firstUpdate = useRef(false);
    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }
        eventBus.on("closeMenuChat", (data: any) => {
            closeSidebar()
        }); 
        return () => {
        };
    }, [])

    const fetchDrafts = async (isDraft: any) => {
        sessionChatsStore.GET_DRAFT(isDraft)
    }
    useEffect(() => {
        if(sessionChatsStore.ruleChat === RuleChat.draft) {
            fetchDrafts(draftStore.activeDraft === DraftStatus.sent ? false : true)
        }
    }, [sessionChatsStore.ruleChat, draftStore.activeDraft])

    const onRenderCellPin = (item?: any, status?: string) => {
        return (
            <ChatHistoryListItemCell
                status={status as string}
                chat={item} 
                chatBySession={() => {
                    if (disabledRate) {
                        showToasty("Vui lòng hoàn thành đánh giá!", "error");
                        return
                    }
                    chatBySession(item, event)
                }}
                disabledRate={disabledRate}
                onHandleClickAction={(chat, action) => onHandleClickAction(chat, action)} 
            />
        );
    };

    const onRenderCellItem = (sessionChats: any, title: string, action: string) => {
        return (
            (sessionChats.length) ? (
                <div> 
                    {title && <p className={`${styles.titleContainer} ${styles.textOverline}`} style={{ position: 'relative' }}>{title}</p>}
                    {sessionChats.map((chat: any, idx: any) => (
                        <div key={idx} className={`${idx == chat.length - 1 ? styles.conversationsNoborder : styles.conversationsContainer}`} style={{ position: 'relative', opacity: disabledRate ? 0.4 : 1 }}>
                            {onRenderCellPin(chat, action)}
                        </div>

                    ))}
                </div>
            ) : null
        )
    }

    const renderChatSessions = () => {
        return (
            <>
                {
                    chatSessionList && chatSessionList?.length && sessionChatsStore.ruleChat === RuleChat.chat ?
                        <>
                            {onRenderCellItem(listChatUnpin, 'Đã ghim', 'pin')}
                            {onRenderCellItem(listChatPin, 'Gần đây', 'unpin')}
                        </> :
                        <EmptyChatHistory themeLocal={themeLocal}/>
                }
            </>
        );
    };

    const draftsByStatus = () => {

        switch (draftStore.keyActiveApprove) {
            case ItemsDraftStatus.all:
              return filter(drafts, ((v: any) => !v?.isDraft));
            case ItemsDraftStatus.pending:
              return filter(drafts, ((v: any) => v?.status === ItemsDraftStatus.pending));
            case ItemsDraftStatus.processed:
              return filter(drafts, ((v: any) => v?.status === ItemsDraftStatus.processed));;
            case ItemsDraftStatus.processing:
              return filter(drafts, ((v: any) => v?.status === ItemsDraftStatus.processing || v?.status === ItemsDraftStatus.noprocess));
            default:
              return [];
          }
    }

    const renderDrafts = () => {
        const _draft_by_status = draftsByStatus()
        const _draft_unsent = filter(drafts, (v) => v?.isDraft)
        const _draft_sent = filter(drafts, (v) => !v?.isDraft)

        return (
            <>
                {   sessionChatsStore.ruleChat === RuleChat.draft && draftStore.activeDraft === DraftStatus.sent ?
                        <TabItemDraft draftSent={_draft_sent}> 
                            { _draft_by_status?.length > 0 ? onRenderCellItem(_draft_by_status, '', 'draft') : <EmptyChatHistory themeLocal={themeLocal} />}
                        </TabItemDraft> :
                    _draft_unsent && _draft_unsent?.length && sessionChatsStore.ruleChat === RuleChat.draft ?
                        <>
                            {onRenderCellItem(_draft_unsent, 'Bản nháp', 'draft')}
                        </> :
                        <EmptyChatHistory themeLocal={themeLocal} />
                }
            </>
        );
    };

    const handleChangeTheme = () => {
        const theme = localStorage.getItem('theme')
        const themeUpdate = theme === 'dark' ? 'light' : 'dark'
        setThemeLocal(themeUpdate)
        themeChange(themeUpdate)
        // toggleColorMode()
    }
    const handleDownload = (e: any) => {
        eventBus.dispatch('download-app', e);
    }

    const buttons: ButtonProps[] =  [
        {
            children: 'Hủy',
            variant: 'outlined',
            buttonSize: 'sm',
            color: 'error',
            type: 'button',
            fullWidth: false,
            onClick: handleClose,
        },
        {
            children: textAction === 'pin' ? 'Ghim' : textAction === 'rename' ? 'Đổi tên' : textAction === 'delete' ? 'Xác nhận' : '',
            variant: 'contained',
            buttonSize: 'sm',
            color: 'error',
            type: 'button',
            fullWidth: false,
            onClick:handleAction,
        },
      ]

    return (
        <Stack direction = {isMobile ? 'column' : 'row'} justifyContent={"space-between"} position="relative">
            {displayOpenSidebar && !isMobile &&
                <div className="" style={{ position: 'absolute', right: '-60px', top: '20px', cursor: 'pointer', transform: 'rotate(180deg)' }} onClick={openSidebar}>
                    <img src={CloseSidebar} />
                </div>
            }
            <div className="">
                <NavbarChatHeader onCloseNavbar = {() => setIsOpen(false)} handleChangeTheme={handleChangeTheme}/>
            </div>
            <div className="" style={{display: 'flex'}}>
                <div className={`${isMobile ? styles.historyContainerMobile : styles.historyContainer} navigation`} id="historyContainer" style={{display: 'flex'}}>
                    <div className={styles.boxNavbar}>
                        <div className={styles.boxNavbarContainer}>
                            <img className={styles.boxheaderLogo} src={logo_mimi} alt="" onClick={redirectHomePage}/>
                            {/* <div className={`chatActiveIcon`} onClick={openChatSession}>
                                <div className="">
                                    <img id="svgImg1" src={ic_chat_v2} style={{paddingTop: '4px'}}/>
                                </div>
                                <span className={`${styles.textfff07} textListAll`}>Danh sách<br/>hội thoại</span>
                            </div> */}
                            <div className="boxRuleSearch" onClick={() => onClickRuleChat(RuleChat.chat)}>
                                <div className={`${sessionChatsStore.ruleChat === RuleChat.chat ? 'chatActiveIcon' : ''}`}>
                                <svg width="36" height="40" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="1" y="0.5" width="47" height="47" rx="23.5" fill="#EE0033"/> <rect x="1" y="0.5" width="47" height="47" rx="23.5" stroke="#FAB2C2"/> <path d="M21.5827 29.8327H21.166C17.8327 29.8327 16.166 28.9993 16.166 24.8327V20.666C16.166 17.3327 17.8327 15.666 21.166 15.666H27.8327C31.166 15.666 32.8327 17.3327 32.8327 20.666V24.8327C32.8327 28.166 31.166 29.8327 27.8327 29.8327H27.416C27.1577 29.8327 26.9077 29.9577 26.7493 30.166L25.4993 31.8327C24.9493 32.566 24.0493 32.566 23.4993 31.8327L22.2493 30.166C22.116 29.9827 21.8077 29.8327 21.5827 29.8327Z" stroke="#FAB2C2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> <path d="M27.8301 23.1667H27.8375" stroke="#FAB2C2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> <path d="M24.4961 23.1667H24.5036" stroke="#FAB2C2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> <path d="M21.1621 23.1667H21.1696" stroke="#FAB2C2" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                                </div>
                                <span className={styles.textfff07}>Hội thoại</span>
                            </div>
                            
                            <div className={styles.v2newchatBox} onClick={() => onClickRuleChat(RuleChat.draft)}>
                                <div className={`${sessionChatsStore.ruleChat === RuleChat.draft ? 'chatActiveIcon' : ''}`}>
                                <svg width="36" height="36" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="#EE0033"/> <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#F3F3F3"/> <path d="M20.666 15.666C17.3327 15.666 15.666 17.3327 15.666 20.666V24.8327C15.666 28.9993 17.3327 29.8327 20.666 29.8327H21.0827C21.3077 29.8327 21.616 29.9827 21.7493 30.166L22.9993 31.8327C23.5493 32.566 24.4493 32.566 24.9993 31.8327L26.2493 30.166C26.4077 29.9577 26.6577 29.8327 26.916 29.8327H27.3327C30.666 29.8327 32.3327 28.166 32.3327 24.8327" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> <path d="M27.6651 16.2113L22.4809 21.3955C22.2836 21.5929 22.0862 21.981 22.0467 22.2639L21.7638 24.2442C21.6586 24.9613 22.1652 25.4613 22.8823 25.3626L24.8625 25.0797C25.1388 25.0402 25.527 24.8428 25.7309 24.6455L30.9151 19.4613C31.8098 18.5666 32.2309 17.5271 30.9151 16.2113C29.5993 14.8955 28.5599 15.3166 27.6651 16.2113Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> <path d="M26.9219 16.9551C27.3627 18.5274 28.5929 19.7577 30.1719 20.2051" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                                </div>
                                <span className={styles.textfff07}>Phản hồi</span>
                            </div>
                        </div>
                        <div className={styles.boxSetting}>
                            <div className={styles.SettingContainer}>
                               {/* <div className={styles.v2IcHover}>
                                    <img src={ic_notification_v2}/>
                               </div> */}
                               <div className={styles.v2IcHover} style={{display: 'flex', justifyContent: 'center'}} onClick={handleChangeTheme}>
                                    <div className="icTheme"></div>
                               </div>
                               <div className={styles.v2IcHover}>
                                    <DropdownMenuDemo/>
                               </div>
                               <div id="Icon-logout" className={styles.v2IcHover} onClick={logOut}>
                                    <img style={{paddingLeft: '5px'}} src={ic_logout}/>
                               </div>
                               <ReactTooltip
                                    className={styles.customTooltipBin}
                                    anchorId="Icon-logout"
                                    place="top"
                                    content="Đăng xuất"
                                />
                            </div>
                        </div>
                    </div>
                    <Render
                        test={loadingChats}
                        fallback={
                            <div className="sessionChatBar">
                                {
                                    <div className={styles.newChatMobile}>
                                        <TabHistory/>
                                    </div>
                                }
                                {
                                    <div className={`${styles.newChatMobile} ${disabledRate && styles.disabled}`} onClick={() => {`${sessionChatsStore.ruleChat === RuleChat.draft ? clickNewContribute() : !params.id ? onClickCloseChatSesionMenu() : onClickNewChat()}`}}>
                                        <div className={styles.newChatMobileButton}>
                                            <img src={addChat}/>
                                            <span className={`${styles.textNewChatMobile}`}>{sessionChatsStore.ruleChat === RuleChat.chat ? 'Thêm mới trò chuyện' : 'Thêm mới phản hồi'}</span>
                                        </div>
                                    </div>
                                }
                                
                                
                                <div className={`${isMobile ? styles.headerChatSeesionMobile : styles.headerChatSeesion}`} style={{ position: 'relative' }}>
                                    <div style={{ justifyContent: 'center', alignItems: 'center', height: '60px', gap: 10, display: 'flex' }}>
                                        <div style={{ color: 'var(--color-text-header-session)', fontSize: '16px', fontWeight: '600', wordWrap: 'break-word', minWidth: 'max-content' }}>
                                            {sessionChatsStore.ruleChat === RuleChat.chat ? 'Danh sách trò chuyện' : draftStore.activeDraft === DraftStatus.sent ? 'Danh sách phản hồi' : 'Danh sách bản nháp'}
                                            {(chatSessionList.length && sessionChatsStore.ruleChat === RuleChat.chat) ? (
                                                <span className={styles.countChatSeesion}>{chatSessionList.length > 9 ? chatSessionList.length : `0${chatSessionList.length}`}</span>
                                            ) : (drafts.length && sessionChatsStore.ruleChat === RuleChat.draft) ? (
                                                <span className={styles.countChatSeesion}>{countDraft}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* <Search/> */}
                                    { chatSessionList && chatSessionList.length && sessionChatsStore.ruleChat === RuleChat.chat ? 
                                        <ClearAllHistory/> : null
                                    } 
                                    {!isMobile && 
                                        <div className="" style={{ position: 'absolute', right: '-24px', top: '20px', cursor: 'pointer' }} onClick={closeSidebar}>
                                            <img src={CloseSidebar} />
                                        </div>
                                    }
                                </div>
                                {
                                    <div className={`${styles.newChatDesktop} ${disabledRate && styles.disabled}`} onClick={() => {`${sessionChatsStore.ruleChat === RuleChat.draft ? clickNewContribute() : !params.id ? onClickCloseChatSesionMenu() : onClickNewChat()}`}}>
                                        <div className={styles.newChatMobileButton}>
                                            <img src={addChat}/>
                                            <span className={styles.textNewChatMobile}>{sessionChatsStore.ruleChat === RuleChat.chat ? 'Thêm mới trò chuyện' : 'Thêm mới phản hồi'}</span>
                                        </div>
                                    </div> 
                                } 
                                <div className={styles.newChatMobile} style={{paddingTop: 0}}>
                                    {
                                        sessionChatsStore.ruleChat === RuleChat.draft && 
                                        <TabDraft isMobile={true}/>
                                    }
                                </div>
                                <div className={styles.newChatDesktop} style={{paddingTop: 0}}>
                                    {
                                        sessionChatsStore.ruleChat === RuleChat.draft && 
                                        <TabDraft isMobile={false}/>
                                    }
                                </div>
                                
                                <div className={`${styles.overflowContainer}`}>
                                    <div 
                                        className={`${styles.chatSettingsSeparator} ${styles.sessionScroll} ${isMobile ? styles.sessionScrollHeightMobile : styles.sessionScrollHeight} xxx`} 
                                        style={{ marginBottom: '30px', height: sessionChatsStore.ruleChat === RuleChat.chat ? `${height + 20}px` : `${height - 48}px` }}
                                    >
                                        {
                                            sessionChatsStore.ruleChat === RuleChat.chat ?
                                                renderChatSessions() : renderDrafts()
                                        }
                                    </div>
                                    <ModalConfirm
                                        show={show}
                                        maxWidth="sm"
                                        title={modalTitle()}
                                        aria-labelledby="contained-modal-title-center"
                                        closeModalConfirm={() => setShow(false)}
                                        handleConfirm={handleAction}
                                        footer={buttons}
                                    >
                                        {textAction !== 'delete' ? (
                                                <TextField 
                                                    defaultValue={textInput} 
                                                    className={styles.chatSettingsSeparator} 
                                                    onChange={onActionInputChange} 
                                                />
                                            ) : (
                                                <p>
                                                    {
                                                        sessionChatsStore.ruleChat === RuleChat.chat ?
                                                            'Bạn sẽ không còn nhìn thấy cuộc trò chuyện này ở đây nữa. Thao tác này cũng sẽ xoá các hoạt động liên quan,chẳng hạn như các câu lệnh, câu trả lời và ý kiến phản hồi.' :
                                                            'Bạn có muốn xóa bản nháp đóng góp câu trả lời. Xác nhận để tiếp tục hoặc hủy để thoát.'
                                                    }

                                                </p>
                                            )}
                                    </ModalConfirm>
                                </div>
                            </div>
                        }
                    >
                        <Stack className="sessionChatBar">
                            <Stack direction="column" spacing={1} width="100%">
                                {Array.from({ length: 15 }).map((_, index) => (
                                    <ListItem key={index} sx={{ padding: 1 }}>
                                        <Skeleton height={56} width="100%" />
                                    </ListItem>
                                ))}
                            </Stack>
                        </Stack>
                    </Render>
                </div>
                {!isMobile && 
                    <div className="buttonToggleClass" onClick={toggleSession}>
                        <img src={CloseSidebar} />
                    </div>
                }
            </div>
        </Stack>
    );
});
