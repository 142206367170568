import { Stack, Typography } from "../Ui";
import * as styles from "./UserChatMessage.style";
interface Props {
    message: string;
}

export const UserChatMessage = ({ message }: Props) => {

    return (
        <Stack marginBottom={"20px"} justifyContent={"flex-end"}>
            <Stack sx={styles.message}>
                <Typography size="text-md" position='relative'>
                    {message}
                </Typography>
            </Stack>
        </Stack>
    );
};
