import { Stack, StackProps, styled, SxProps } from '@mui/material'

export const popoverPaper: SxProps = {
  borderRadius: 1,
}

export const header: SxProps = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
}

export const title: SxProps = {
  textAlign: 'center',
}

export const addOnComponent: SxProps = {
  position: 'absolute',
  left: 16,
  top: 16,
  padding: 0,
}

export const closeButton: SxProps = {
  position: 'absolute',
  right: 16,
  top: 16,
  padding: 0,
}

export const PopoverActionsStyled = styled(Stack)<StackProps>(({ theme }) => ({
  padding: theme.spacing(1),
  flexDirection: 'row',
  borderTop: `2px solid ${theme.palette.divider}`,
}))

export const content: SxProps = {
  padding: 2,
  paddingTop: 0,
}
